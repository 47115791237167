/* eslint-disable tailwindcss/no-custom-classname */

import jadegomez from '~/eikon-404/page-404-jade-gomez/index.html?raw';
import apokimonte from '~/eikon-404/page-404-apokimonte/index.html?raw';
import camwizardd from '~/eikon-404/page-404-camwizardd/index.html?raw';
import claraEko from '~/eikon-404/page-404-claraEko/index.html?raw';
import DaL0lff from '~/eikon-404/page-404-DaL0lff/index.html?raw';
import fionafionafionafionafiona from '~/eikon-404/page-404-fionafionafionafionafiona/index.html?raw';
import JuniaYoshida from '~/eikon-404/page-404-JuniaYoshida/index.html?raw';
import LenouilleLaFripouile from '~/eikon-404/page-404-LenouilleLaFripouile/index.html?raw';
import liloudemierre from '~/eikon-404/page-404-liloudemierre/index.html?raw';
import LyaKelo from '~/eikon-404/page-404-LyaKelo/index.html?raw';
import TimotheeSiffert from '~/eikon-404/page-404-TimotheeSiffert/index.html?raw';
import louisequeru from '~/eikon-404/page-404-louisequeru/index.html?raw';

const pages = [
  jadegomez,
  apokimonte,
  camwizardd,
  claraEko,
  DaL0lff,
  fionafionafionafionafiona,
  JuniaYoshida,
  LenouilleLaFripouile,
  liloudemierre,
  LyaKelo,
  TimotheeSiffert,
  louisequeru,
];

export default function Page404() {
  const random = Math.floor(Math.random() * 12);
  return <div dangerouslySetInnerHTML={{__html: pages[random]}} />;
}
