import {toast as notify} from 'sonner';
import {getClientLocales} from 'remix-utils/locales/server';
import {captureRemixErrorBoundaryError, withSentry} from '@sentry/remix';
import {motion, useReducedMotion} from 'framer-motion';
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import React, {useEffect} from 'react';
import styles from './tailwind.css?url';
import {TooltipProvider} from './components/ui/tooltip';
import {Prompt} from './components/prompt';
import {json, LoaderFunctionArgs} from '@remix-run/node';
import {BreadcrumbsLink} from '~/components/breadcrumbs';
import * as gtag from '~/lib/gtags.client';
import {Header} from '~/components/header';
import {getAccount} from '~/lib/auth.server';
import {getToast} from 'remix-toast';
import {Toaster} from '~/components/ui/sonner';
import {Canvas} from '~/components/Canvas';
import {Button} from './components/ui/button';
import {HomeIcon} from '@heroicons/react/24/solid';
import {db} from '~/db.server';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible';
import {ChevronRightIcon} from '@heroicons/react/20/solid';
import Page404 from '~/components/page-404';
import {getKindeSession} from '@kinde-oss/kinde-remix-sdk';

export const links = () => {
  return [
    {rel: 'stylesheet', href: styles},
    {rel: 'preconnect', href: 'https://fonts.googleapis.com'},
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'true',
    },
    {
      href: 'https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600;700&family=Pixelify+Sans:wght@400..700&display=swap',
      rel: 'stylesheet',
    },
  ];
};

export const loader = async ({request}: LoaderFunctionArgs) => {
  const {toast, headers} = await getToast(request);
  const locales = getClientLocales(request);

  const account = await getAccount(request);

  let sessionHeaders: Headers = headers;
  if (account) {
    const session = await getKindeSession(request);
    if (session) {
      sessionHeaders = (await session.refreshTokens()) || headers;
    }
  }

  // Get basic edition info
  const currentEdition = await db.edition.findFirst({
    where: {active: true},
    orderBy: {startDate: 'desc'},
    include: {
      themes: {
        select: {name: true},
        where: {main: true},
      },
    },
  });

  let currentEditionParticipants = 0;
  if (currentEdition) {
    currentEditionParticipants = await db.participant.count({
      where: {editionId: currentEdition.id, neuchatel: true},
    });
  }

  return json(
    {
      toast,
      account,
      gaTrackingId: process.env.GA_TRACKING_ID,
      env: process.env.NODE_ENV,
      locales,
      clientSecret: process.env.STRIPE_CLIENT_SECRET,
      currentEdition,
      neuchatelAvailable:
        !!currentEdition &&
        currentEditionParticipants <= currentEdition.maxParticipants,
    }
    // {headers: sessionHeaders}
  );
};

export const handle = {
  breadcrumb: () => <BreadcrumbsLink to="/" label="Home" />,
};

export const meta = () => [
  {title: 'Epic Game Jam'},
  {
    description:
      'Create an epic game in 43 hours by yourself or with a team and try to keep up with the crazy stuff happening in the game jam!',
  },
];

function Layout({children}: {children: React.ReactNode}) {
  const shouldReduceMotion = useReducedMotion();
  const data = useRouteLoaderData<typeof loader>('root');
  const {gaTrackingId, env} = data ?? {};

  return (
    <html lang="en" className="mx-auto max-w-screen-xl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <Meta />
        <meta property="og:image" content="/images/2024-banner.jpeg" />
        <Links />
      </head>
      <TooltipProvider delayDuration={200}>
        <motion.body
          className="relative m-1 flex h-full min-h-[calc(100vh-1rem)] max-w-screen-xl flex-col gap-4 rounded-sm border-2 border-primary bg-background px-2 pb-2 md:m-2"
          initial={{opacity: 0, scaleY: shouldReduceMotion ? 1 : 0.5}}
          animate={{opacity: 1, scaleY: 1}}
          transition={{delay: 0.6}}
        >
          {process.env.NODE_ENV === 'development' || !gaTrackingId ? null : (
            <>
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
              />
              <script
                async
                id="gtag-init"
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
                }}
              />
            </>
          )}
          <Header />
          {children}
          <ScrollRestoration />
          <Scripts />
          <script
            dangerouslySetInnerHTML={{__html: `window.env = "${env}";`}}
          />
          <Toaster position="top-center" />
        </motion.body>
      </TooltipProvider>
    </html>
  );
}

function App() {
  const {gaTrackingId, toast} = useLoaderData<typeof loader>();
  const location = useLocation();

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  useEffect(() => {
    if (toast?.type === 'error') {
      notify.error(toast.message);
    }
    if (toast?.type === 'success') {
      notify.success(toast.message);
    }
  }, [toast]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  const is404 = isRouteErrorResponse(error) && error.status === 404;

  return is404 ? (
    <Page404 />
  ) : (
    <Layout>
      <div className="flex flex-col-reverse gap-8 md:grid md:grid-cols-2">
        <div className=" space-y-4">
          {isRouteErrorResponse(error) ? (
            <>
              <h1 className="text-3xl md:text-6xl">
                {error.status} {error.statusText}
              </h1>
              <p>{error.data}</p>
            </>
          ) : error instanceof Error ? (
            <div>
              <h1 className="text-3xl md:text-6xl">Error</h1>
              <p>{error.message}</p>
              <Collapsible className="mt-6">
                <CollapsibleTrigger className="flex items-center gap-1 text-sm [&[data-state=open]_svg]:rotate-90">
                  <ChevronRightIcon className="size-5" />
                  <span>View stack trace</span>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <pre className="my-4 min-w-0 overflow-auto bg-foreground/10 p-2 text-xs">
                    {error.stack}
                  </pre>
                </CollapsibleContent>
              </Collapsible>
            </div>
          ) : (
            <h1 className="text-3xl md:text-6xl">Unknown Error</h1>
          )}

          <div className="mb-8">
            <Button variant="outline" asChild>
              <Link to="/">
                <HomeIcon className="size-5" />
                <span>Go Home</span>
              </Link>
            </Button>
          </div>
        </div>

        <Canvas
          backgroundImage="/images/404_bg.png"
          className="max-w-full"
          canvasStyles={{
            maxWidth: '800px',
            maxHeight: '800px',
            height: 'auto',
            aspectRatio: '1',
          }}
        />
      </div>

      <Prompt />
    </Layout>
  );
}
